import type { NextPage } from 'next';

import { FooterNav } from '@/components/footerNav';

const footerNav = [
  // {
  //   url: "/about",
  //   label: "About",
  // },
  // {
  //   url: "/contact",
  //   label: "Contact",
  // },
  {
    url: '/blog',
    label: 'Blog',
  },
];

export const PageFooter: NextPage = () => {
  return (
    <footer className="footer">
      <div className="content-wrapper">
        <div className="footer__nav">
          {footerNav.map((n) => (
            <FooterNav url={n.url} key={n.url} label={n.label} />
          ))}
        </div>
        <p className="copyright">
          © 2022 Copyright Sen Hongo. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
