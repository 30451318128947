import styles from './article.module.scss';

type Props = {
  tags: string[];
};

export const Tags = ({ tags }: Props) => {
  return (
    <section>
      {tags.map((t) => (
        <span className={styles.tag} key={t}>
          {t}
        </span>
      ))}
    </section>
  );
};
