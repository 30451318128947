import type { NextPage } from 'next';

import Link from 'next/link';

import { HeaderNav } from '@/components/headerNav';

const headerNav = [
  // {
  //   url: "/about",
  //   label: "About Me",
  // },
  {
    url: '/blog',
    label: 'Blog',
  },
];

export const PageHeader: NextPage = () => {
  return (
    <header className="header">
      <div className="content-wrapper">
        <Link href="/" className="header__site-title-link">
          <h1 className="header__site-title">
            <span className="header__logo" />
            Legend of Code
          </h1>
        </Link>
        <nav className="header__nav">
          {headerNav.map((n) => (
            <HeaderNav url={n.url} key={n.url} label={n.label} />
          ))}
        </nav>
      </div>
    </header>
  );
};
