import NextHead from 'next/head';

export const Head = () => {
  return (
    <NextHead>
      <title>
        Sen Hongo — freelance software engineer and business consultant
      </title>
      <meta
        name="description"
        content="Sen Hongo — freelance software engineer and business consultant"
      />
    </NextHead>
  );
};
