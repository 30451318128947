import type { IArticle } from './types';
import { ArticleListItem } from './articleListItem';
import styles from './article.module.scss';

type Props = {
  articles: IArticle[];
};

export const ArticleList = ({ articles }: Props) => {
  return (
    <section className={styles.list}>
      {articles.map((a) => (
        <ArticleListItem article={a} key={a.id} />
      ))}
    </section>
  );
};
