import type { IArticle } from './types';
// import Link from 'next/link';
import { Tags } from './tags';
import styles from './article.module.scss';

type Props = {
  article: IArticle;
  showAuthor?: boolean;
};

export const Meta = ({ article, showAuthor = false }: Props) => {
  const { date, tags, series } = article.data;
  return (
    <div className={styles.meta}>
      {showAuthor && (
        <>
          <span className={styles.author}>Sen Hongo</span>
          <span>|</span>
        </>
      )}
      <span>Published on {date}</span>
      <span>|</span>
      {series.length > 0 && (
        <>
          <span>
            part of <a className={styles.series}>{series}</a>
          </span>
          <span>|</span>
        </>
      )}
      {tags && <Tags tags={tags} />}
    </div>
  );
};
