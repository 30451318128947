import styles from './intro.module.scss';

export const Intro = () => {
  return (
    <section className={styles.root}>
      <div className={styles.image}></div>
      <div className={styles.titles}>
        <h2 className={styles.title}>
          Hi, I&apos;m Sen — freelance software engineer and business
          consultant.
        </h2>
        <p className={styles.subtitle}>
          I help companies and people build web interfaces and design systems,
          turning dreams into products.
        </p>
      </div>
    </section>
  );
};
