import type { IArticle } from './types';
import { Meta } from './meta';
import styles from './article.module.scss';

type Props = {
  article: IArticle;
};

export const Article = ({ article }: Props) => {
  const {
    data: { title },
    content,
  } = article;
  return (
    <article>
      <h1 className={styles.title}>{title}</h1>
      <Meta article={article} showAuthor />
      <section
        className={styles.contents}
        dangerouslySetInnerHTML={{ __html: content }}
      ></section>
    </article>
  );
};
