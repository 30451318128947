import type { IArticle } from './types';
import Link from 'next/link';
import { Meta } from './meta';
import styles from './article.module.scss';

type Props = {
  article: IArticle;
};

export const ArticleListItem = ({ article }: Props) => {
  const { title, description } = article.data;
  return (
    <article className={styles.listItem}>
      <Link href={`/blog/${article.id}`}>
        <h2>{title}</h2>
      </Link>
      <Meta article={article} />
      {description && <p className={styles.subtitle}>{description}</p>}
    </article>
  );
};
