import Link from 'next/link';

type Props = {
  url: string;
  label: string;
};

export const HeaderNav = ({ url, label }: Props) => {
  return <Link href={url}>{label}</Link>;
};
