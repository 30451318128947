import type { GetStaticProps } from 'next';
import type { IArticle } from '@/components/article';

import { Head } from '@/components/head';

import { posts } from '@/library/posts';

import { Intro } from '@/components/intro';
import { ArticleList } from '@/components/article';
import { PageHeader } from '@/components/pageHeader';
import { PageFooter } from '@/components/pageFooter';

type Props = {
  articles: IArticle[];
};

const Home = ({ articles }: Props) => {
  return (
    <>
      <Head />
      <PageHeader />

      <main className="main">
        <div className="content-wrapper">
          <Intro />

          <ArticleList articles={articles} />
        </div>
      </main>

      <PageFooter />
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const res = await posts();

  const articles = res.map((article) => ({
    id: article.id,
    data: article.contents.data,
  }));

  return {
    props: {
      articles,
    },
  };
};

export default Home;
